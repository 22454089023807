<template>
  <div>
    <div class="w-full text-right mr-2">

      <router-link to="/home" class="text-sm md:text-base text-grey border border-dashed border-white border-opacity-25 rounded py-2 px-4">بازگشت به داشبورد</router-link>
    </div>
    <div class="w-full">
      <br>
      <p class="px-2 py-2 rounded border-0 text-white">
        درگاه کنونی
        <span class="text-alert">{{ this.activeGateway }}</span>

      </p>
      <br>
      <p>
        <vs-select :selected="this.activeGateway" color="#333" class="w-full" placeholder=" تغییر درگاه به ... " v-model="paymethod" >
          <vs-select-item icon="sign" :disabled="item.title === activeGateway ? true :  false" :key="index" :value="item" :text="item.title" v-for="(item,index) in gateways" />
       </vs-select>
        <vue-button-spinner
          :is-loading="isLoading"
          :disabled="isLoading"
          >
          <span @click="changeGateway()" >
           تغییر درگاه
          </span>

        </vue-button-spinner>
      </p>
    </div>

  </div>
</template>
<script>
import VueButtonSpinner from 'vue-button-spinner'
import VueJsonPretty from "vue-json-pretty";
import "vue-json-pretty/lib/styles.css";
export default {
  components: {
    VueJsonPretty,
    VueButtonSpinner
  },
  data() {
    return {
      activeGateway: '',
      gateways : [
          {'id': 'zibal', 'title': 'Zibal'},
          {'id': 'zarinpal', 'title': 'Zarinpal'},
      ],
      paymethod: {},
      isLoading: false,
      status: '',
    };
  },
  watch: {

  },
  methods: {
    getActiveGateway () {
      this.$http.get(
        '/admin/invoice/active/payment/method'
      ).then((res) => {
        this.activeGateway = res.data.params.Method;
        this.paymethod = res.data.params.Method;
      })
    },
    changeGateway () {
      this.isLoading = true
      this.$http.post('/admin/invoice/payment/method',{payment_method: this.paymethod.id})
        .then(response => {
          this.activeGateway = response.data.params.Method
          this.isLoading = false
          this.status = false // or success
        })
        .catch(error => {
          console.error(error)
          this.isLoading = false
          this.status = true //or error
        })
    },
    deleteByValue (key,val,myobject) {
    for(var f in myobject) {
        if(myobject[f][key] === val) {
            myobject[f] = ''
        }
    }
}


  },
  created() {
    this.getActiveGateway()

  }
};
</script>
<style>

button:disabled {
      pointer-events:none;
      color: #bfcbd9;
      cursor: not-allowed;
      background-image: none;
      background-color: #eef1f6 !important;
      border-color: #d1dbe5;
    }
</style>
